<template>
  <div class="bg">
    <div class="title">南瓜藤侍卫</div>
    <div class="leftMain">
      <div class="leftMain_top">
        <div class="leftMain_topIn">f
          <ul>
            <li>
              <div class="liIn">
                <h3>总交易额</h3>
                <p class="shu"><span class="shu1">¥ {{ (homeAll.payAmount) }}</span></p>
                <div class="zi">
                  <div :style="{color:dataList.yesterday.payAmount>dataList.today.payAmount?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.payAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.payAmount)>Number(dataList.today.payAmount)"/>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.payAmount)<=Number(dataList.today.payAmount)"/>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
            <li>
              <div class="liIn">
                <h3>退款金额</h3>
                <p class="shu"><span class="shu2">¥ {{ (homeAll.refundAmount) }}</span><i></i></p>
                <div class="zi">
                  <div :style="{color:Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.refundAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.refundAmount)<Number(dataList.today.refundAmount)"></a-icon>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
            <li>
              <div class="liIn">
                <h3>客单价</h3>
                <p class="shu"><span class="shu3">¥ {{ (homeAll.customerPrice) }}</span></p>
                <div class="zi">
                  <div :style="{color:Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.customerPrice) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.customerPrice)<Number(dataList.today.customerPrice)"></a-icon>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
            <li>
              <div class="liIn">
                <h3>用户数</h3>
                <p class="shu"><span class="shu4">{{ homeAll.userCount || 0 }}</span></p>
                <div class="zi">
                  <div
                    :style="{color:Number(dataList.yesterday.userCount)>Number(dataList.userCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.userCount || 0 }}个</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.userCount)>Number(dataList.today.userCount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.userCount)<Number(dataList.today.userCount)"></a-icon>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="leftMain_middle">
        <div class="leftMain_middle_left">
          <div class="leftMain_middle_leftIn">
            <h3>订单交易额日统计</h3>
            <!-- 为ECharts准备一个具备大小（宽高）的Dom -->
            <div class="biaoge" style="width:100%; height:25vh" id="orderHistogramChart"></div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
        <div class="leftMain_middle_right">
          <div class="leftMain_middle_rightIn">
            <h3>商品销量排行榜</h3>
            <div class="biaoge biaoge_pai" style="width:100%; height:25vh">
              <div class="biaoge_paiIn">
                <ul>
                  <li v-for="(item,index) in this.starAmountList" :key="item.id" style="padding: 0.01rem 0">
                    <div class="liIn">
                      <div class="liIn_left"><span class="bot"></span><span class="zi">{{item.goodsName}}</span>
                      </div>
                      <div class="liIn_line">
                        <div class="line_lineIn" :style="'width:35 '+ item.salesNum"></div>
                      </div>
                      <p class="num">{{item.salesNum}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
      </div>
      <div class="leftMain_middle">
        <div class="leftMain_middle_left">
          <div class="leftMain_middle_leftIn">
            <h3>订单销售额折线图</h3>
            <div class="biaoge" style="width:100%; height:25vh" id="orderLineChart"></div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
        <div class="leftMain_middle_right">
          <div class="leftMain_middle_rightIn">
            <h3>订单信息(今日)</h3>
            <div class="biaoge biaoge_bi" style="width:100%; height:25vh">
              <ul>
                <li>
                  <div class="liIn">
                    <p class="shu shu1">{{ dataList.today.orderCount }}</p>
                    <p class="zi">总订单数</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu2">{{ dataList.today.refundCount }}</p>
                    <p class="zi">退款单数</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu3">¥ {{ (dataList.today.goodsAmount) }}</p>
                    <p class="zi">商品金额</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu4">¥ {{ (dataList.today.shippingAmount) }}</p>
                    <p class="zi">运费</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu5">{{ dataList.today.userCount || 0 }}</p>
                    <p class="zi">今日新增用户数</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu6">{{ dataList.today.inviteUserCount || 0 }}</p>
                    <p class="zi">今日新增邀请用户数</p>
                  </div>
                </li>
              </ul>

            </div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="rightMain">
      <div class="rightMain_bottom">
        <div class="rightMain_bottomIn">
          <h3>客服账号信息</h3>
          <div class="biaoge biaoge_list" style="width:100%; height:36vh">
            <div class="biaoge_listIn">
              <ul class="ul_title">
                <ul class="ul_title">
                  <li>客服</li>
                  <li>手机号</li>
                  <li>客服账号</li>
                </ul>
              </ul>
              <div class="ul_list">
                <div class="ul_listIn">
                  <ul class="ul_con" v-for="(item,index) in this.shopPayList" :key="item.userId">
                    <li>{{item.nickName}}</li>
                    <li>{{item.mobile}}</li>
                    <li>{{item.id}}kf</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <span class="border_bg_leftTop"></span>
          <span class="border_bg_rightTop"></span>
          <span class="border_bg_leftBottom"></span>
          <span class="border_bg_rightBottom"></span>
        </div>
      </div>
      <div class="rightMain_bottom">
        <div class="rightMain_bottomIn">
          <h3>用户消费排行榜</h3>
          <div class="biaoge biaoge_list" style="width:100%; height:36vh">
            <div class="biaoge_listIn">
              <ul class="ul_title">
                <li>用户</li>
                <li>手机号</li>
                <li>消费金额</li>
              </ul>
              <div class="ul_list">
                <div class="ul_listIn">
                  <ul class="ul_con" v-for="(item,index) in this.userPayList" :key="item.userId">
                    <li>{{item.nickName}}</li>
                    <li>{{item.mobile}}</li>
                    <li>{{item.payAmount}}</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <span class="border_bg_leftTop"></span>
          <span class="border_bg_rightTop"></span>
          <span class="border_bg_leftBottom"></span>
          <span class="border_bg_rightBottom"></span>
        </div>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
import {tableMixin} from '@/store/table-mixin'
import * as echarts from 'echarts'
import moment from 'moment';
import {orderAmountChart, orderTimeChart} from "@/api/statistics/order";
import {homeAllResult, homeResult} from "@/api/statistics/home";
import {userSta,userStaKf} from "@/api/statistics/user";
import {starSta } from "@/api/statistics/star";
import {pageShop, listShop, delShop, updateShopRecommend} from '@/api/shop/shop'


export default {
  name: 'dashBroad',
  components: {},
  mixins: [tableMixin],
  data() {
    return {
      activeKey: 1,
      /*销售数据选择*/
      isSalesSelect: undefined,
      isCartSelect: undefined,
      dataList: {
        today:{},
        yesterday:{},
      },
      homeAll:{},
      userPayList:[],
      shopPayList:[],
      userPayParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
        pageNum:1,
        pageSize:100,
        sortField:'payAmount',
        sortOrder:'desc'
      },
      shopPayParam: {
        pageNum:1,
        pageSize:100,
        sortField:'payAmount',
        sortOrder:'desc'
      },
      starAmountList:[],
      starAmountParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
        pageNum:1,
        pageSize:6,
        sortField:'payAmount',
        sortOrder:'desc'
      },
      orderAmountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime:undefined,

      },
    }
  },
  filters: {},
  created() {
    this.getHomeStatistics();
    this.getHomeAllStatistics();
    this.drawUserPayList();
    this.drawShopPayList();
    this.drawStarAmountList();
  },
  mounted() {
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 768 * 100 + 'px';
    this.orderHistogram();
    this.orderLineChart();
  },
  destroyed(){
    document.documentElement.style.fontSize = '';
  },
  computed: {},
  watch: {},
  methods: {
    moment,
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.activeKey = index;
    },
    getHomeStatistics() {
      homeResult().then(response => {
        this.dataList = response.data
      });
    },
    getHomeAllStatistics() {
      homeAllResult().then(response => {
        this.homeAll = response.data
      });
    },
    orderHistogram() {
      //指定图表的配置项和数据
      // // 添加
      var chartDom = document.getElementById("orderHistogramChart");
      var myChart = echarts.init(chartDom);
      var option;

      orderAmountChart(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = [];
          let xAxis = [];
          res.data.xxAxis.forEach(e=>{
            xAxis.push(e.substring(8,10)+"日");
          })
          res.data.yyAxis.payAmountList.forEach(e=>{
            yaxis.push(e/10000);
          })
          option = {
            title: {
              text: '',
              subtext: ''
            },
            grid: {
              x: 40,
              y: 40,
              x2: 20,
              y2: 20,

            },
            xAxis: {
              data: xAxis,
              axisLabel: {
                /*inside: true,*/
                interval: 0,
                textStyle: {
                  color: '#fff',
                  fontSize: 12
                }
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                symbol: ['none', 'arrow'],
                symbolOffset: 12,
                lineStyle: {
                  color: '#fff',
                }
              },
              z: 10
            },
            yAxis: {
              type: 'value',
              name: '单位：万元',
              axisLine: {
                show: true,
                symbol: ['none', 'arrow'],
                symbolOffset: 12,
                lineStyle: {
                  color: '#fff',
                }
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 12
                }
              }
            },

            dataZoom: [
              {
                type: 'inside'
              }
            ],
            series: [
              { // For shadow
                type: 'bar',
                itemStyle: {
                  color: 'rgba(0,0,0,0.05)'
                },
                barGap: '-100%',
                barCategoryGap: '40%',
                animation: false
              },
              {
                type: 'bar',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 0, color: '#0efdff'},
                      {offset: 0.5, color: '#188df0'},
                      {offset: 1, color: '#188df0'}
                    ]
                  )
                },
                emphasis: {
                  label: {
                    show: true,
                    formatter: function (params) {
                      return params.value;
                    },
                    //一下为label的一些配置项，设置外边框，背景颜色，字体颜色等
                    backgroundColor: 'rgba(61,126,255,0.09)',
                    borderColor: '#ff543d',
                    borderWidth: 0.5,
                    borderRadius: 2,
                    padding: 6,
                    position: 'top',
                    textStyle: {
                      color: '#ff543d',
                      fontSize: 14,
                      marginLeft: 0,
                      lineHeight: 20
                    }
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                      0, 0, 0, 1,
                      [
                        {offset: 0, color: '#2378f7'},
                        {offset: 0.7, color: '#2378f7'},
                        {offset: 1, color: '#0efdff'}
                      ]
                    )
                  }
                },
                data: yaxis
              }
            ]
          }
          option && myChart.setOption(option);
        }
      });

      // Enable data zoom when user click bar.
      /*var zoomSize = 6;
      myChart.on('click', function (params) {
          console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
          myChart.dispatchAction({
              type: 'dataZoom',
              startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
              endValue: dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
          });
      });*/
    },

    orderLineChart() {
      // // 添加
      var chartDom = document.getElementById("orderLineChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单销售额', '运费金额','退款金额']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis,
                axisLabel: {
                  textStyle: {
                    color: '#fff',
                    fontSize: 12
                  }
                },
              }
            ],
            yAxis: [
              {
                type: 'value',
                axisLine: {
                  show: true,
                  symbol: ['none', 'arrow'],
                  symbolOffset: 12,
                  lineStyle: {
                    color: '#fff',
                  }
                },
              }
            ],
            series: [
              {
                name: '运费金额',
                type: 'line',
                stack: 'shippingAmount',
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(211,89,13,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(197,105,67,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#ee8e22' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#ee8e22' // 设置标点的颜色为蓝色
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.shippingPayAmountList
              },
              {
                name: '退款金额',
                type: 'line',
                stack: 'refundAmount',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(218,119,119,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(239,5,5,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundAmountList
              },
              {
                name: '订单销售额',
                type: 'line',
                stack: 'payAmount',
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  },
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.payAmountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },

    drawUserPayList() {
      userSta(this.userPayParam).then((res) => {
        this.userPayList = res.data.records
      });
    },
    drawShopPayList() {
      userStaKf(this.shopPayParam).then((res) => {
        this.shopPayList = res.data
      });
    },
    drawStarAmountList() {
      starSta(this.starAmountParam).then((res) => {
        this.starAmountList = res.data.records
      });
    },
  }
}
</script>
<style lang="less" scoped>
@import "css/icon.css";
@import "css/public.css";
.bg {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  background: url(images/bg2.jpg) no-repeat;
  background-size: cover;
  padding: 0rem 0.2rem;

}

.title {
  margin: 0 auto;
  width: 110%;
  font-size: 0.12rem;
  line-height: 0.3rem;
  color: rgba(14, 253, 255, 1);
  text-align: center;
  font-weight: bold;
}

.border_bg_leftTop {
  position: absolute;
  left: -0.008rem;
  top: -0.04rem;
  width: 0.37rem;
  height: 0.05rem;
  display: block;
  background: #01279d url(images/title_left_bg.png) no-repeat;
  background-size: cover;
}
.biaoge_paiIn {
  -webkit-animation: 14s gundong linear infinite normal;
  animation: 14s gundong linear infinite normal;
  position: relative;
}
.border_bg_rightTop {
  position: absolute;
  right: -0.01rem;
  top: -0.01rem;
  width: 0.1rem;
  height: 0.1rem;
  display: block;
  background: url(images/border_bg.jpg) no-repeat;
  background-size: cover;
}

.border_bg_leftBottom {
  position: absolute;
  left: -0.008rem;
  bottom: -0.008rem;
  width: 0.1rem;
  height: 0.1rem;
  display: block;
  background: url(images/border_bg.jpg) no-repeat;
  background-size: cover;
}

.border_bg_rightBottom {
  position: absolute;
  right: -0.01rem;
  bottom: -0.01rem;
  width: 0.08rem;
  height: 0.08rem;
  display: block;
  background: url(images/title_right_bg.png) no-repeat;
  background-size: cover;
}

.leftMain {
  width: 75%;
  float: left;
  padding-right: 0.1rem;
  padding-top: 0.1rem;
}

.rightMain {
  width: 25%;
  float: left;
  padding-top: 0.1rem;
}

.leftMain_top {
  width: 100%;
  padding-bottom: 0.1rem;
}

.leftMain_top ul {
  display: flex;
  display: -webkit-flex;
}

.leftMain_top ul li {
  float: left;
  width: 25%;
  padding-right: 0.1rem;
}

.leftMain_top ul li:last-child {
  padding: 0;
}

.leftMain_top ul li .liIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.leftMain_top ul li .liIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.leftMain_top ul li .liIn .shu {
  font-size: 0.12rem;
  color: rgba(14, 253, 255, 1);
  font-family: dig;
  margin-bottom: 0.02rem;
}

.leftMain_top ul li .liIn .shu i {
  font-size: 0.04rem;
  margin-left: 0.06rem;
  font-style: normal;
}

.leftMain_top ul li .liIn .zi {
  font-size: 0.04rem;
  color: #fff;
  position: relative;
  z-index: 10;
}

.leftMain_top ul li .liIn .zi .span1 {
  margin-right: 0.1rem;
}


.leftMain_middle {
  width: 100%;
  padding-bottom: 0.1rem;
  display: flex;
  display: -webkit-flex;
}

.leftMain_middle .leftMain_middle_left {
  width: 60%;
  padding-right: 0.1rem;
}

.leftMain_middle .leftMain_middle_left .leftMain_middle_leftIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.leftMain_middle .leftMain_middle_left .leftMain_middle_leftIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.leftMain_middle .leftMain_middle_left .leftMain_middle_leftIn .biaoge {
  min-height: 200px;
}

.leftMain_middle .leftMain_middle_right {
  width: 40%;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge {
  min-height: 200px;
}

/*左边中间部分排行榜*/
.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai {
  width: 100%;
  overflow: hidden;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  color: #fff;
  font-size: 0.06rem;
 /* height: 0.18rem;*/
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_left {
  width: 65%;
  position: relative;
  padding-left: 0.14rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_left .bot {
  width: 0.08rem;
  height: 0.08rem;
  background: #f78cf3;
  border-radius: 1000px;
  display: block;
  position: absolute;
  left: 0.02rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn2 .liIn_left .bot {
  background: #e7feb8;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn3 .liIn_left .bot {
  background: #fdea8a;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn4 .liIn_left .bot {
  background: #8ff9f8;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn5 .liIn_left .bot {
  background: #d890fa;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn6 .liIn_left .bot {
  background: #05d1fc;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_left zi {
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_line {
  width: 58%;
  height: 0.08rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2000px;
}

.biaoge_paiIn {
  -webkit-animation: 14s gundong linear infinite normal;
  animation: 14s gundong linear infinite normal;
  position: relative;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_line .line_lineIn {
  width: 100%;
  height: 0.08rem;
  background: #f78cf3;
  border-radius: 100px;
  -webkit-animation: widthMove1 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn2 .liIn_line .line_lineIn {
  background: #e7feb8;
  -webkit-animation: widthMove2 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn3 .liIn_line .line_lineIn {
  background: #fdea8a;
  -webkit-animation: widthMove3 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn4 .liIn_line .line_lineIn {
  background: #8ff9f8;
  -webkit-animation: widthMove4 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn5 .liIn_line .line_lineIn {
  background: #d890fa;
  -webkit-animation: widthMove5 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn6 .liIn_line .line_lineIn {
  background: #05d1fc;
  -webkit-animation: widthMove6 2s ease-in-out;
}

@-webkit-keyframes widthMove1 {
  0% {
    width: 0%;
  }
  100% {
    width: 98.5%;
  }
}

@-webkit-keyframes widthMove2 {
  0% {
    width: 0%;
  }
  100% {
    width: 88.5%;
  }
}

@-webkit-keyframes widthMove3 {
  0% {
    width: 0%;
  }
  100% {
    width: 68.5%;
  }
}

@-webkit-keyframes widthMove4 {
  0% {
    width: 0%;
  }
  100% {
    width: 40.5%;
  }
}

@-webkit-keyframes widthMove5 {
  0% {
    width: 0%;
  }
  100% {
    width: 22.5%;
  }
}

@-webkit-keyframes widthMove6 {
  0% {
    width: 0%;
  }
  100% {
    width: 10.5%;
  }
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .num {
  width: 17%;
  font-family: dig;
  padding-left: 0.02rem;
}

/*左边底部*/
.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  width: 100%;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul li {
  width: 33.3%;
  text-align: center;
  margin-bottom: 0.05rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul li .shu {
  font-size: 0.14rem;
  color: rgba(14, 253, 255, 1);
  font-family: dig;
  padding: 0.12rem 0 0.02rem;
  font-weight: normal;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul li .zi {
  font-size: 0.06rem;
  color: #fff;
}

/*右边部分*/
.rightMain .rightMain_top {
  width: 100%;
  padding-bottom: 0.1rem;
}

.rightMain .rightMain_topIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.rightMain .rightMain_topIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.rightMain .rightMain_topIn .biaoge {
  min-height: 200px;
}

.rightMain .rightMain_bottom {
  width: 100%;
}

.rightMain .rightMain_bottomIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.rightMain .rightMain_bottomIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

/*右下角表格*/
.rightMain .rightMain_bottomIn .biaoge {
  min-height: 200px;
}

.rightMain .rightMain_bottomIn .biaoge_list {
  overflow: hidden;
  position: relative;
}

.rightMain .rightMain_bottomIn .biaoge_list .biaoge_listIn .ul_list {
  overflow: hidden;
  position: relative;
}

.rightMain .rightMain_bottomIn .biaoge_list .biaoge_listIn .ul_listIn {
  -webkit-animation: 14s gundong linear infinite normal;
  animation: 14s gundong linear infinite normal;
  position: relative;
}

@keyframes gundong {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -30vh, 0);
    transform: translate3d(0, -30vh, 0);
  }
}

.rightMain .rightMain_bottomIn .biaoge_list ul {
  display: flex;
  display: -webkit-flex;
  width: 100%;
}

.rightMain .rightMain_bottomIn .biaoge_list .ul_title {
  background: linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
}

.rightMain .rightMain_bottomIn .biaoge_list .ul_con {
  border-bottom: 0.008rem solid rgba(14, 253, 255, 0.5);
}

.rightMain .rightMain_bottomIn .biaoge_list ul li {
  width: 30%;
  text-align: center;
  color: #fff;
  font-size: 0.06rem;
  height: 0.2rem;
  line-height: 0.2rem;
}



</style>
