import request from '@/utils/request'


// 查询店铺列表
export function listShop(query) {
  return request({
    url: '/shop/shop/list',
    method: 'get',
    params: query
  })
}

// 查询店铺分页
export function pageShop(query) {
  return request({
    url: '/shop/shop/page',
    method: 'get',
    params: query
  })
}

// 查询店铺详细
export function getShop(data) {
  return request({
    url: '/shop/shop/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺
export function addShop(data) {
  return request({
    url: '/shop/shop/add',
    method: 'post',
    data: data
  })
}

// 修改店铺
export function updateShop(data) {
  return request({
    url: '/shop/shop/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺
export function delShop(data) {
  return request({
    url: '/shop/shop/delete',
    method: 'post',
    data: data
  })
}
// 是否推荐
export function updateShopRecommend(data) {
  return request({
    url: '/shop/shop/updateShopRecommend',
    method: 'post',
    data: data
  })
}
// 平台客服
export function ptkf(data) {
  return request({
    url: '/shop/shop/ptkf',
    method: 'get',
    params: data
  })
}
// 获取常用语
export function queryCommonMessage(data) {
  return request({
    url: '/user/commondef/page',
    method: 'get',
    params: data
  })
}
//平台上传oss
export function queryAutographSign(data) {
  return request({
    url: '/api/v2/token/sign',
    method: 'post',
    params: data
  })
}
// queryAutographSign:'/api/v2/token/sign',// 直传oss  签名

// 查询用户信息列表
export function queryKfUserList(data) {
  return request({
    url: '/api/v2/user/queryUserList',
    method: 'post',
    data: data
  })
}
// 查询客服问题列表
export function queryKfUserQusetion(query) {
  return request({
    url: '/common/quick/reply/page',
    method: 'get',
    params: query
  })
}

// 查询客服列表
export function queryKfUserLists(query) {
  return request({
    url: '/biz/user/pageKf',
    method: 'get',
    params: query
  })
}

export function queryKfUserChatMessage(query) {
  return request({
    url: '/customer/notice/record/page',
    method: 'get',
    params: query
  })
}
// 查询聊天记录
export function queryKfNoticePage(data) {
  return request({
    url: '/api/v2/customer/notice/page',
    method: 'post',
    data: data
  })
}

// 查询聊天记录总数
export function queryKfNoticeCount(data) {
  return request({
    url: '/api/v2/customer/notice/count',
    method: 'post',
    data: data
  })
}

// 添加聊天记录
export function addKfNoticeCount(data) {
  return request({
    url: '/api/v2/customer/notice/add',
    method: 'post',
    data: data
  })
}

